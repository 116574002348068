<template>
  <div>
    <UnhealthyBanner
      v-if="isUnhealthy"
      class="sticky top-0 z-50"
    />

    <ImpersonatorBanner
      v-else-if="impersonatorName"
      :impersonator-name="impersonatorName"
      class="sticky top-0 z-50"
    />
    <ReleaseBanner
      v-else-if="showReleaseBanner"
      class="sticky top-0 z-50"
    />
    <NavBar
      v-model:display-mobile-menu="displayMobileMenu"
      :banner-shown-above="!!(impersonatorName || showReleaseBanner || isUnhealthy)"
    />

    <USlideover
      v-model="displayMobileMenu"
      side="left"
      class="top-12"
    >
      <SidebarContent
        include-header
        @close="displayMobileMenu = false"
      />
    </USlideover>

    <main class="flex h-full min-h-screen flex-col justify-between bg-white sm:pt-12 print:pt-0">
      <slot />

      <div
        class="flex items-center justify-center bg-tertiary py-4 text-xs text-grey-blue"
        @click="handleFooterClick"
      >
        © 2024 TruRating Ltd
      </div>
    </main>

    <ReleaseModal
      v-if="canReadReleases"
      :model-value="(newReleaseAvailable && latestRelease?.releaseType === 'major')"
    />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const router = useRouter()

// internal refs
const displayMobileMenu: Ref<boolean> = ref(false)

// store actions
const userStore = useUserStore()
const releaseStore = useReleaseStore()
const healthCheckStore = useHealthCheckStore()

const { currentUserPreferences, currentUser } = storeToRefs(userStore)
const { latestRelease } = storeToRefs(releaseStore)
const { healthcheck } = storeToRefs(healthCheckStore)

healthCheckStore.fetchApiHealthCheck()
await releaseStore.fetchReleases()
await userStore.fetchCurrentUserPreferences()
await userStore.fetchCurrentUser() // fetches user for permissions
const impersonatorName = userStore.fetchImpersonatorName()

// computed
const isUnhealthy: ComputedRef<boolean> = computed(() => !!(healthcheck.value && healthcheck.value.status !== 'Healthy'))
const newReleaseAvailable: ComputedRef<boolean> = computed(() => {
  if (!latestRelease.value || !currentUserPreferences.value) {
    return false
  }

  const lastAcknowledgedRelease = currentUserPreferences.value.find(p => p.name === 'LastAcknowledgedRelease')
  const lastIgnoredRelease = currentUserPreferences.value.find(p => p.name === 'LastIgnoredRelease')

  if (lastAcknowledgedRelease?.value !== latestRelease.value.releaseNumber
    && lastIgnoredRelease?.value !== latestRelease.value.releaseNumber) {
    return true
  }

  return false
})
const canReadReleases = computed(() => checkPermissions({ permissions: 'Releases.Read' }))
const showReleaseBanner = computed(() =>
  canReadReleases.value
  && newReleaseAvailable.value
  && latestRelease.value
  && latestRelease.value.releaseType === 'minor')

function handleFooterClick() {
  const frontend_build_number = config.public.dockerImageTag
  const backend_build_number = healthcheck.value?.results?.version_check.data.self
  const current_url = window.location.href

  navigator.clipboard.writeText(JSON.stringify({
    frontend_build_number,
    backend_build_number,
    current_url,
    currentUserId: currentUser.value?.id,
    currentUserEmail: currentUser.value?.email,
    currentUserRole: currentUser.value?.roleId,
    impersonatorName: currentUser.value?.impersonatorName
  }))
}

// watchers
watch(
  () => router.currentRoute.value,
  () => {
    // if the current route changes, and the menu is open, close the mobile menu
    if (displayMobileMenu.value) {
      displayMobileMenu.value = false
    }
  })

useHead({
  meta: [
    { name: 'robots', content: 'noindex, nofollow' }
  ]
})
</script>
