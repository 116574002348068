import { defineStore } from 'pinia'
import type { HealthCheck } from '~/types'

export const useHealthCheckStore = defineStore('healthcheck', () => {
  const healthcheck: Ref<HealthCheck | undefined> = ref()

  async function fetchApiHealthCheck(): Promise<void> {
    try {
      healthcheck.value = (await $hubFetch('/api/healthcheck')) as HealthCheck
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.warn(error.value?.statusMessage || error)
      healthcheck.value = {
        status: 'error',
        results: error.value?.statusMessage || error
      }
    }
  }
  return {
    healthcheck,

    fetchApiHealthCheck
  }
})
